// export const BASE_URL = 'http://3.109.142.88:6000/'
// export const BASE_APP = 'http://3.109.142.88'
// export const SERVER_URL = "http://3.109.142.88"

// LIVE
export const BASE_URL = 'https://www.aakashgangaopen.in/'
export const BASE_APP = 'https://www.aakashgangaopen.in'
export const SERVER_URL = "https://www.aakashgangaopen.in"

// STAGING
// export const BASE_URL = 'http://159.65.153.60/'
// export const BASE_APP = 'http://159.65.153.60'
// export const SERVER_URL = "http://159.65.153.60"

// export const BASE_URL = 'http://13.126.168.170:8001'
// export const BASE_APP = 'http://13.126.168.170:8001'
// export const SERVER_URL = "http://13.126.168.170:8001"
