export const PATH = {
  LOGIN: "/login",
  SUNEDITOR: "/sun-editor",
  FORGOTPASSWORD: "/forgotpassword",
  MOBILELOGIN: "/mobilelogin",
  REGISTRATION: "/registration",
  RESETPASSWORD: "/reset-password/:token",
  VERIFYOTP: "/verifyotp",
  VERIFYEMAIL: "/activate/:token",
  PERSONAL_DETAILS: "/personaldetails",
  PAYMENT_DETAILS: "/paymentdetails",
  PEDASHBOARD: "/peDashboard",
  PRDASHBOARD: "/prDashboard",
  REVIEWERDETAILS: "/reviewerDetails",
  MUDRAKOSH: "/mudraKosh",
  HISTORY: "/history",
  ARTICLELANDING: "/access-articles",
  ARTICLELANDINGSOCIETY: "/access-society",
  SEARCH_HEADER: "/search-result/:search",
  AUTHORGUIDELINE: "/authorGuidelines",  
  PREVIEWARTICLE: "/perviewArticle/:id",
  VIEW_PUBLISHED_ARTICLE: "/viewPublishedArticle/:id",
  VIEW_PUBLISHED_ARTICLE_NEW: "/:abb/:id/article/:arttitle",
  ARTICLEREGISTRATIONPAYMENT: "/manage-payment/:id",
  READINGLIST: "/readingList",
  MYDICTIONARY: "/my-dictionary",
  MYPROFILE: "/myprofile",
  EDITPROFILE: "/editprofile",
  SUBMITARTICLE: "/submitarticle",
  EDITARTICLE: "/editarticle/:id",
  POPUPSCREENS: "/popoupscreens",
  PREVIEWPROFILE: "/previewprofile",
  CONTACT_US: "/contactus",
  NEED_HELP: "/need-help",
  // FAQ_AKASHGANGA: "/faq-akashganga",
  COOKIE: "/cookie-settings",
  WIP: "/work-in-progress-kaashi",
  WIP_AKASHGANGA: "/work-in-progress-akashganga",
  TERMSCONDITION: "/terms-conditions",
  ERROR: "*",
  DASHBOARD: {
    DASHBOARD: "/dashboard",
    SUBMIT_ARTICLE: "/dashboard/submit-article",
    EDIT_ARTICLE: "/dashboard/edit-article/:id",
    MY_ARTICLE_DRAFT: "/myArticle/draft",
    MY_ARTICLE_UNDER_REVIEW: "/myArticle/submit",
    MY_ARTICLE_APPROVED: "/myArticle/approved",
    MY_ARTICLE_REJECTED: "/myArticle/rejected",
    MY_ARTICLE_PUBLISHED: "/myArticle/published",
    PUBLISHING_EDITOR: "/dashboard/publishing-editor",
    DASHBOARD_EDITOR: "/dashboard/society-editor",
    DASHBOARD_EDITOR_SE_USERPROFILE: "/dashboard/society-userProfile/:id",
    DASHBOARD_EDITOR_PE_LISTING: "/dashboard/society-editor/pr-listing",
    DASHBOARD_SE_EDIT_ARTICLE: "/dashboard/society-editor/:id/edit-article",
    DASHBOARD_EDITOR_PR_INVITED_LIST: "/dashboard/society-editor/pr-invited-list",
    DASHBOARD_EDITOR_PAYMENT_BYPASS: "/dashboard/society-editor/payment-bypass",
    DASHBOARD_EDITOR_BULK_ORDER: "/dashboard/society-editor/special-deal-details",
    DASHBOARD_EDITOR_PR_APPOINTMENT_HISTORY: "/dashboard/society-editor/pr-appointment-history",
    NAVIGATOR_PUBLISHING_EDITOR: "/dashboard/publishing-editor-appoint-role",
    PUBLISHING_EDITOR_DRAFT: "/publishing-editor/requests",
    PUBLISHING_EDITOR_ACCEPTED: "/publishing-editor/accepted",
    PUBLISHING_EDITOR_SENT_FOR_REVIEW: "/publishing-editor/review",
    PUBLISHING_EDITOR_PEER_REVIEW: "/publishing-editor/peerReview",
    PUBLISHING_EDITOR_APPROVED: "/publishing-editor/approved",
    PUBLISHING_EDITOR_REVISION: "/publishing-editor/revision",
    PUBLISHING_EDITOR_REJECTED: "/publishing-editor/rejected",
    PUBLISHING_EDITOR_NOT_ACCEPTED: "/publishing-editor/notAccepted",
    PEER_REVIEWER_REQUESTS: "/peer-reviewer/requests",
    PEER_REVIEWER_ACCEPTED: "/peer-reviewer/accepted",
    PEER_REVIEWER_REVIEWED: "/peer-reviewer/reviewed",
    PEER_REVIEWER_NOT_ACCEPTED: "/peer-reviewer/notAccepted",
    PEER_REVIEWER: "/dashboard/peer-reviewer",
    ARTICLE: {
      VIEW_REPORT: "/dashboard/article/:id/view-report",
      REVIEW_ARTICLE: "/dashboard/article/:id/review-article",
      SUBMIT_REVIEW: "/dashboard/article/submit-review/:id",
    },

  },
  AAKASHGANGA_HOMEPAGE: "/",
  AAKASHGANGA_ABOUT: "/about-us",
  REFER_AND_EARN: "/refer-and-earn",
  SITEMAP: "/sitemap",
  SELL_PAGE: "/domestic-societies-partnerships",
  CONFERENCE_PAPERS: "/conference-papers",
  ALL_ARTICLES: "/all-research-articles",
  EXPLORE_JOURNALS: "/mega-journals/:id",
  EXPLORE_MEGA_ALLARTICLE: "/mega-journals/:id/all-articles",
  MEGA_EDITORIAL_BOARD: "/mega-journals/:id/editorial-board",
  EXPLORE_SOCIETY_ALLARTICLE: "/society-journals/:discipline/:id/all-articles",
  EXPLORE_SOCIETY: "/society-journals/:discipline/:id",
  SOCIETY_EDITORIAL_BOARD: "/society-journals/:discipline/:id/editorial-board",
  PUBLISHING_EDITORS: "/explore-journals/:id/publishing-editor",
  EDITORIAL_BOARD: "/explore-journals/:id/editorial-board",
  ARTICLE_PROCESSION_ChARGES: "/apc",
  AUTHOR_SUBMISSION_GUIDELINES: "/author-submission-guidelines",
  PEER_REVIEWER_GUIDELINES: "/peer-reviewer-guidelines",
  HOW_WE_PUBLISH: "/how-we-publish",
  ETHICS_POLICY: "/ethics-policy",
  PEER_REVIEWER_MODAL: "/peer-review-models",
  PUBLISH_WITH_US: "/publish-with-us",
  PUBLISHING_EDITOR_AND_RESPONSIBILITY: "/publishing-editor-roles-and-responsibilities",
  SELL_PAGE_FOREIGN: "/foreign-societies-partnerships",
  UNLOCKING_KNOWDGE: "/open-access",
  WELCOME_TO_MEGAJOURNALS: "/about-megajournals",
  AAKASHGANGA_FAQ: "/faq",
  AAKASHGANGA_CONTACT_US: "/contact-us",
  JOURNAL_GET_IN_TOUCH: "/journal/get-in-touch"
};
