import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { Pagination } from "semantic-ui-react";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import "./index.scss";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import copyicon from "../../../assets/img/icons/copy.svg";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboard";
import {
    getArticleDetails,
    getSearchUsers,
    getPEDetails,
    getSEArticleList,
    saveRevokeCoupon,
    sendPrInvitation,
    sendpaymentByPass,
    appointPRRole,
} from "../../../store/apiCalls/dashboard";
import PEListing from "./PEListing";
// E:\React JS\ag-front\src\components\common\Select.js
import Select from "../../../components/common/Select";
import { getAllMasterData } from "../../../store/apiCalls/fetchmaster";
import { useSelector, useDispatch } from "react-redux";
import RecommendedArticles from "../dashboard/RecommendedArticles";
import copy from "copy-to-clipboard";
const SEArticleList = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.LoginReducer);
    const [show, setShow] = useState(false);
    const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
    const handleClose = () => setShow(false);
    const [updateRequestList, setUpdateRequestList] = useState(false);
    const [revokeCouponPop, setRevokeCouponPop] = useState(false);
    const [revokeId, setRevokeId] = useState();
    const [revokeReason, setRevokeReason] = useState();
    const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [addPeerReviewerPop, setaddPeerReviewerPop] = useState(false);
    const { id } = jwt_decode(localStorage.usertoken);
    const [activeTab, setActiveTab] = useState("draft");
    const location = useLocation();
    const [latestPage, setLatestPage] = useState(1);
    const [latestArticlesTotalPages, setlatestArticlesTotalPages] = useState(1);
    const [peActiveTab, setPEActiveTab] = useState("draft");
    const [peData, setPEData] = useState([]);
    const [totalPEData, setTotalPEData] = useState([]);
    const [peerreviewlist, setpeerreviewlist] = useState([
        {
            name: "",
            email: "",
            university: "",
            subject: "",
        },
    ]);
    const [articleData, setArticleData] = useState([]);
    const [search, setSearch] = useState();
    const [searchResult, setSearchResult] = useState([]);
    const [bulkDeal, setBulkDeal] = useState([]);
    const [Subjectmaster, setSubjectmaster] = useState([]);

    const [articleTotalData, setArticleTotalData] = useState([]);
    const [page, setPage] = useState(1);
    const limit = 10;
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const articleId = location?.state?._id

    const [collapseActive, setIsActive] = useState(false);
    const expandClick = (event) => {
        setIsActive((current) => !current);
    };
    async function getMasterList() {
        // const msterlst = await getAllMasterData();

        // if (msterlst.status === 200) {
        //     const subjectLists = [];
        //     const subjectSpecialization = [];
        //     msterlst.data.subjectdata.forEach((element) => {
        //         const data = { key: element._id, value: element.subjectName };
        //         subjectLists.push(data);
        //         subjectSpecialization[element._id] = element.specializationId;
        //     });

        //     setSubjectmaster(subjectLists);
        //     // setSubjectmaster(msterlst.data.specializationdata);
        //     console.log("==>", subjectLists);
        //     //   setspecializationList(msterlst.data.specializationdata);
        //     //   const journalMaster = [];
        //     //   const journalType = [];
        //     //   msterlst.data.jouranlData.forEach((element) => {
        //     //     const data = { key: element._id, value: element.journalType };
        //     //     journalType.push(data);
        //     //     journalMaster[element._id] = element.journalName;
        //     //   });

        //     //   setgernalMaster(journalMaster);
        //     //   setgernalType(journalType);
        //     //   setdesciplineMaster(msterlst.data.disciplineData);

        // }
    }

    
    const fetchPRInvitation = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            status: "paymentByCoupon",
            page: page,
            limit,
        };
        const response = await getSEArticleList(headers, params);
        // console.warn(response)
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setSearchResult(response?.data?.data);
            setBulkDeal(response?.data?.data?.[0]?.bulkdeal)
            // setTotalPEData(response?.data?.data); 
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again");
        }
    };
    // const fetchPublishingEditor = async (tab) => {
    //     dispatch({ type: "SET_LOADER", payload: true });
    //     const params = {
    //         userId: id,
    //         page: page,
    //         limit,
    //         peStatus: tab,
    //     };
    //     const response = await getPEDetails(headers, params);
    //     if (response?.status === 200) {
    //         dispatch({ type: "SET_LOADER", payload: false });
    //         setPEData(response?.data?.data?.data);
    //         setTotalPEData(response?.data?.data);
    //     } else {
    //         dispatch({ type: "SET_LOADER", payload: true });
    //         return toast.error("Please try again");
    //     }
    // };

    // const getPEType = (type) => {
    //     fetchPublishingEditor(type);
    //     setPEActiveTab(type);
    // };
    /*serach by name  */

    // const searchByName = async (search) => {
    //     const activate = true;
    //     let data = { page, limit, search, activate };
    //     const response = await getSearchUsers(headers, data);
    //     setSearchResult(response?.data?.data?.data);
    //     console.log(searchResult);
    // };

    // const fetchArticleData = async (tab) => {
    //     dispatch({ type: "SET_LOADER", payload: true });
    //     const params = {
    //         userId: id,
    //         status: tab,
    //         page: page,
    //         limit: limit,
    //     };
    //     const response = await getArticleDetails(headers, params);
    //     if (response?.status === 200) {
    //         dispatch({ type: "SET_LOADER", payload: false });
    //         setArticleData(response?.data?.data?.article);
    //         setArticleTotalData(response?.data?.data);
    //     } else {
    //         dispatch({ type: "SET_LOADER", payload: false });
    //         return toast.error("Please try again");
    //     }
    // };

    // const getArticleType = (type) => {
    //     fetchArticleData(type);
    //     setActiveTab(type);
    // };
    // async function sentReminder(dataid) {
    //     // const articleId = window.location.pathname.split("/")[5];
    //     const data = {
    //         status: "reminder",
    //         userId: id,
    //         id: dataid,
    //     };
    //     const response = await sendPrInvitation(headers, data);
    //     // console.log(response);
    //     if (response?.status === 201) {
    //         toast.success("Successfully Sent!!");
    //     } else {
    //         toast.error("Please try again!!");
    //     }
    // }
    // async function sentPrInvitation() {
    //     // const articleId = window.location.pathname.split("/")[5];
    //     const data = {
    //         peerreviewlist: peerreviewlist,
    //         userId: id,
    //     };
    //     const response = await sendPrInvitation(headers, data);
    //     // console.log(response);
    //     if (response?.status === 201 || response?.status === 200) {
    //         setaddPeerReviewerPop(false);
    //     } else {
    //         toast.error("Please try again!!");
    //     }
    // }
    // async function paymentByPass(dataid, method) {
    //     // const articleId = window.location.pathname.split("/")[5];
    //     const data = {
    //         // peerreviewlist: peerreviewlist,
    //         userId: id,
    //         id: dataid,
    //         byPass: method
    //     };
    //     const response = await sendpaymentByPass(headers, data);
    //     // console.log(response);
    //     if (response?.status === 201 || response?.status === 200) {
    //         setaddPeerReviewerPop(false);
    //     } else {
    //         toast.error("Please try again!!");
    //     }
    // }
    // const invitePEhandleUpdate = (index, updatedFields) => {
    //     setpeerreviewlist(prevList => {
    //         const newList = [...prevList];
    //         newList[index] = { ...newList[index], ...updatedFields };
    //         return newList;
    //     });
    // };
    // Handle adding a new peer review
    // const handleAdd = () => {
    //     setpeerreviewlist(prevList => [
    //         ...prevList,
    //         { name: "", email: "", university: "", subject: "" }
    //     ]);
    // };

    // // Handle removing a peer review
    // const handleRemove = (index) => {
    //     setpeerreviewlist(prevList => {
    //         const newList = [...prevList];
    //         newList.splice(index, 1);
    //         return newList;
    //     });
    // };
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle null or undefined date
        return new Date(dateString).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };
    // console.log(peerreviewlist)
    // const typeOfArticle = (data) => {
    //     const article = masterData?.typeOfArticledata?.find(article => article?._id === data);
    //     return article ? article?.articleName : 'ID not found';
    // };

    useEffect(() => {
        fetchPRInvitation()
        // getMasterList()
    }, []);

    const handleRevokeReason = async (data) => {
        // console.log("data",data?.target?.value);
        setRevokeReason(data?.target?.value);

    }
    const sentRevokeCoupon = async (data) => {
       const params = {
        revokeReason: revokeReason,
        revoked: true,
        articleId: revokeId,
       }
       const response = await saveRevokeCoupon(headers, params);

    }
    const findLeftMonths = (startDate) => {
        
        const start = new Date(startDate);
        const current = new Date(); // Get the current date
        
        // Calculate the difference in years and months
        const yearsDiff = current.getFullYear() - start.getFullYear();
        const monthsDiff = current.getMonth() - start.getMonth();
        
        // Total months difference
        const totalMonths = yearsDiff * 12 + monthsDiff;
        const returnTotalMonths = bulkDeal?.dealDuration?.exTotalDealtimeMonths - totalMonths;
    
        // return startDate;
        return Math.abs(returnTotalMonths);
    }
   
    const profileAs = localStorage.getItem("dropdownToggleVal");
    
    return (
        <>
            <DashboardLayout>
                <>
                    <div className="d-flex justify-content-between">
                        <p className="draftTitle">
                            Special Deal Details <span>Old Deals</span>
                        </p>
                        <div className="d-flex">
                            <div class="reportBox me-3">
                                <ul>
                                    <li>
                                        <small>Available Free Articles</small>
                                        <p className="pt-2">{bulkDeal?.dealDuration?.exTotalFreeArticles - searchResult?.length || 0}</p>
                                    </li>
                                    <li>
                                        <small>Used Articles</small>
                                        <p  className="pt-2">{searchResult?.length || 0}</p>
                                    </li>

                                </ul>
                            </div>
                            <div class="reportBox">
                                <ul>
                                    <li>
                                        <small>Total TIme</small>
                                        <p className="pt-2">{bulkDeal?.dealDuration?.exTotalDealtimeMonths || 0}  <span className="monthbox">months</span></p>
                                    </li>
                                    <li>
                                        <small>Time Remaining</small>
                                        <p className="pt-2">{findLeftMonths(bulkDeal?.dealDuration?.startDate) || 0}  <span className="monthbox">months</span></p>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className=" bulkdealcard align-items-center mt-5">
                        <div>
                            <p className="d-flex align-items-center gap-3">
                                Please Share the
                                <div className="couponcodebox cursor-pointer" onClick={(e) => copy(bulkDeal?.couponCode || "No Coupon Available")}>
                                {bulkDeal?.couponCode || "No Coupon Available"}  <img className="ms-2" src={copyicon} alt="" />
                                </div>
                                to allow the author to bypass the full article payment during the submission journey.
                            </p>
                        </div>
                        <div className="d-flex">


                        </div>
                    </div>

                    <div className="mt-4">
                        <table className="table tableadmin">
                            <thead>
                                <tr className="text-center">
                                    <th>Available Articles</th>
                                    <th>Deal Time</th>
                                    <th>APC Cost</th>
                                    <th>Coupon Code</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    {/* <th></th> */}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-center">
                                    <td><span class="text-link">{bulkDeal?.dealDuration?.exTotalFreeArticles || "N/A"} </span></td>
                                    <td>{bulkDeal?.dealDuration?.exTotalDealtimeMonths || "N/A"} Months</td>
                                    <td>{bulkDeal?.apcCost || "N/A"}</td>
                                    <td>{bulkDeal?.couponCode || "No Coupon Available"}</td>
                                    <td>{formatDate(bulkDeal?.dealDuration?.startDate) || "N/A"}</td>
                                    <td>{formatDate(bulkDeal?.dealDuration?.endDate) || "N/A"}</td>
                                    <td><span class="text-link">Delete</span></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="mt-5">
                        <p className="draftTitle">
                            Utilized Article Details
                        </p>
                        <table  className="table tableadmin">
                            <thead>
                                <tr>
                                    <th class=""></th>
                                    <th class=""><span>Author<b class="icon-sort"></b></span></th>
                                    <th class=""><span>Email ID<b class="icon-sort"></b></span></th>
                                    <th class=""><span>Title<b class="icon-sort"></b></span></th>
                                    <th class=""><span>Article Type</span></th>
                                    
                                    <th class=""><span>Coupon Used<b class="icon-sort"></b></span></th>
                                    <th class="megaHeaderTh text-center"><span>Actions</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                { searchResult.length > 0 && searchResult?.map((searchResult, index) => (
                                    <tr>
                                        <td>{index+1}.</td>
                                        <td><a class="text-link" href={`/dashboard/society-userProfile/${searchResult?.user[0]._id}`}>{searchResult?.user[0].fullName}</a></td>
                                        <td><a class="text-link" href="/ag-admin/aakashganga/users/profile/66a4eb1a85deceec222b4264">{searchResult?.user[0].email}</a></td>
                                        <td><a class="text-link" href="/ag-admin/aakashganga/users/articleDetails/published/undefined">{searchResult?.title || "--"}</a></td>
                                        <td>{searchResult.typeofarticles[0].articleName}</td>
                                        <td>{formatDate(searchResult.createdAt)}</td>
                                        <td class="text-center">
                                            <a class="text-link px-2" href="/ag-admin/aakashganga/article/draft/articleDetail/draft/66a54c8985deceec222b59b0">View Article</a>
                                            <a class="text-link px-2" onClick={(e) => {setRevokeCouponPop(true), setRevokeId(searchResult?._id), setRevokeReason("")}}>Revoke Coupon</a>
                                        </td>
                                    </tr>
                                ))}
                                
                                {/* <tr>
                                    <td>2.</td>
                                    <td><a class="text-link" href="/dashboard/society-userProfile/659e707920681afc451639c8">P R Kumaraswamy</a></td>
                                    <td><a class="text-link" href="/ag-admin/aakashganga/users/profile/659e707920681afc451639c8">Kumaraswamy@gmail.com</a></td>
                                    <td><a class="text-link" href="/ag-admin/aakashganga/users/articleDetails/published/undefined">Editorial: Welcome To Minorities In World Politics!</a></td>
                                    <td>Commentaries</td>
                                    <td>19 Jul 2024</td>
                                    <td class="text-center">
                                        <a class="text-link px-2" href="/ag-admin/aakashganga/article/draft/articleDetail/draft/66974cadc51a4d86277bea94">View Article</a>
                                        <a class="text-link px-2" onClick={(e) => setRevokeCouponPop(true)}>Revoke Coupon</a>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </>
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-lg authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Grant Details</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            // onClick={(e) => handleOTPClose(e)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="grantDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Grant Name</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Available for</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>URL of Grant available for </b>
                                        <span className="textBlue">
                                            Urban climate change management and society
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Description</b>
                                        <span className="textGray">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam, quis labore et dolore
                                            magna aliqua labore et dolore magna...
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Eligibility</b>
                                        <ul className="listText">
                                            <li>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                            </li>
                                            <li>
                                                Sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Know More</b>
                                        <ul className="knowList">
                                            <li>
                                                <p>
                                                    <i>Grant Received On</i>
                                                    <small>12 May 2023</small>
                                                </p>
                                                <p>
                                                    <i>Valid Till</i>
                                                    <small>25 Aug 2023</small>
                                                </p>
                                            </li>

                                            <li>
                                                <p>
                                                    <i>Amount Received</i>
                                                    <small>INR 7000</small>
                                                </p>
                                                <p>
                                                    <i>Amount Remaining</i>
                                                    <small>INR 6500</small>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <i>Transaction ID</i>
                                                    <small>826475844855</small>
                                                </p>
                                                <p>
                                                    <i>Invoice</i>
                                                    <div className="downloadBOx">
                                                        <a href="" download></a>
                                                        <strong>Download</strong>
                                                    </div>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={handleClose}
                        >
                            CLOSE
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={revokeCouponPop}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered authorModal modal-w-500"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Revoke Coupon</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => setRevokeCouponPop(false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0 pt-0">
                        <div className="peerreview">
                            <p className="text-left">Are you sure you want to revoke this coupon? An email will be shared with the author informing them of your decision. </p>
                            <label  className="labelForm mt-4 px-0">Please provide a suitable reason <span className="red-text fs-16">(Mandatory)</span></label>
                            <textarea  className="form-control"
                      type="text"
                      placeholder="Please enter the content..."
                      maxLength={500} name="revokeReason" value={revokeReason} onChange={handleRevokeReason}></textarea>
                            {/* <a className="d-block text-start fw-bold" onClick={(e) => { handleAdd() }}>+ Add More Peer Reviewer</a> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => {setRevokeCouponPop(false),sentRevokeCoupon()}}
                            // onClick={sentRevokeCoupon}
                        >
                           Yes, Revoke
                        </button>
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => setRevokeCouponPop(false)}
                        >
                          No, Cancel
                        </button>
                        <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout>

        </>
    );
};
export default SEArticleList;
