import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import FeedbackFlow from "../Feedback";
import AcceptModal from "../../containers/autherFlow/prdashboard/AcceptModal";
import ReferLoginModal from "../Modal/ReferLoginModal";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import YoutubeIcon from "../../assets/assets_html/images/youtube-circle-logo.svg";
import FacebookIcon from "../../assets/assets_html/images/icon-facebook.svg";
import InstagramIcon from "../../assets/assets_html/images/icon-instagram.svg";
import TwitterIcon from "../../assets/assets_html/images/icon-xtwitter2.svg";
import LinkedinIcon from "../../assets/assets_html/images/icon-linkedIn.svg";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../Modal/MobileModalAlert";
import MobileModalReg from "../Modal/MobileMobleReg";

function Footer() {
  const [uploadArticle, setUploadArticle] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const [mobileAlert, setMobileAlert] = useState(false);
  const [mobileAlertReg, setMobileAlertReg] = useState(false);
  const token = localStorage.getItem("usertoken");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showLoginRefer, setShowLoginRefer] = useState(false);
  const modalBody = ``;
  const getStatus = () => {
    setShowFeedbackModal(false);
    setShowAccept(true);
  };
  const getStatusClose = () => {
    setShowFeedbackModal(false);
  };

  const scrollLink = (e) => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {/* <!-- Footer Start --> */}
      <div className="ag_redline_wrap">
        <AcceptModal
          showAccept={showAccept}
          setShowAccept={setShowAccept}
          title={"Your feedback has been successfully received."}
          body={modalBody}
          btnText="Ok"
        />
        {showFeedbackModal && (
          <FeedbackFlow
            handleModalClose={() => {}}
            code={""}
            modalStat={""}
            status={getStatus}
            statusClose={getStatusClose}
          />
        )}
        <h2 className="tagh2">
          An Indian Open Access Journal Platform for Research Dissemination
        </h2>
      </div>
      <div className="ag_footer_wrap">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-12">
              <div className="ag_ftr_logo">
                <img
                  className="tagimg"
                  src="/assets/images/logo.png"
                  alt="Aakashganga Footer Logo"
                />
              </div>
            </div>
            <div
              className="col-lg-5 col-12 customfootercontent"
            >
              <div className="ag_ftr_box">
                <p className="description tagp">
                  Are you a Professor, Researcher? Or a member of a University,
                  Academic Society, or Publishing Institution?
                </p>
                <p className="description tagp">
                  Are you ready to elevate your work and reputation? Welcome to
                  Aakashganga - India's all-inclusive, Open Access, and
                  automated publishing platform.
                </p>
                <p className="description tagp" >
                  Affordable, accessible, and designed to make your research
                  shine. With us, you're not just researching or publishing,
                  you're making an impact.
                </p>
                <ul className="ag_ftr_links tagul">
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="https://vikramshilaedu.in/about"
                      target="_blank"
                    >
                      Who We Are
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="https://vikramshilaedu.in/teams"
                      target="_blank"
                    >
                      The Team
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="https://vikramshilaedu.in/advisory"
                      target="_blank"
                    >
                      Advisory Board
                    </Link>
                  </li>

                  <li className="tagli">
                    <Link
                      className="taga"
                      to="https://vikramshilaedu.in"
                      target="_blank"
                    >
                      Vikramshila Education
                    </Link>
                  </li>

                  <li className="tagli">
                    <Link
                      className="taga blinkWrap"
                      to="https://www.youtube.com/watch?v=w68GPd_pliU"
                      target="_blank"
                    >
                      <b>How to Register Video</b>
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga blinkWrap2"
                      to="https://www.youtube.com/watch?v=IaPrg-5O-Ks&t=5s"
                      target="_blank"
                    >
                      <b> Author Guidelines Video</b>
                    </Link>
                  </li>

                </ul>
              </div>
              <div className="footer-align-bottom">
                {!token && (
                  <Link to="javascript:;" className="ag_ftr_register" onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}>
                        Publish Your Article
                      </Link>
                )}
                {token && (
                  <Link
                    onClick={() =>isMobile ? setMobileAlert(true):setUploadArticle(true)}
                    className="ag_ftr_register"
                  >
                    Publish Your Article
                  </Link>
                )}
                 <Link
                      className="siteLink"
                      to="/sitemap"
                      target="_blank"
                    >
                        Sitemap
                    </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-12 customfootercontent"
            >
              <div className="ag_ftr_box">
                <h4 className="tagh4">publish</h4>
                <ul className="ag_ftr_links">
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/apc"
                      target="_blank"
                    >
                      Article Publishing Charge
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/peer-review-models"
                      target="_blank"
                    >
                      Peer Review Models
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/publish-with-us"
                      target="_blank"
                    >
                      Publish with Us
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/how-we-publish"
                      target="_blank"
                    >
                      How We Publish
                    </Link>
                  </li>
                  
                </ul>
                <h4 className="tagh4">explore</h4>
                <ul className="ag_ftr_links tagul">
                  <li className="tagli">
                    <Link to="/all-research-articles" className="taga" >
                    All Research Articles
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="/open-access"
                      target="_blank"
                      className="taga"
                    >
                      Open Access
                    </Link>
                  </li>
                  
                  <li className="tagli">
                    <Link
                      to="/about-megajournals"
                      target="_blank"
                      className="taga"
                    >
                      Mega Journals
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/domestic-societies-partnerships"
                      target="_blank"
                    >
                      Partnerships: Societies, Universities, Institutes
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/foreign-societies-partnerships"
                      target="_blank"
                    >
                      Foreign Societies, Universities, Institutes
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                    // to="javascript:;"
                      to="/conference-papers"
                      // target="_blank"
                      onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
                      className="taga"
                    >
                      Publish Conference Papers
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/ethics-policy"
                      target="_blank"
                    >
                    Publishing Ethics Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-align-bottom">
                <h4 className="tagh4" style={{paddingLeft: "60px"}}>follow us</h4>
                <ul className="ag_ftr_socials footermngBox">
                  <li className="tagli">
                    <Link
                      to="https://www.youtube.com/watch?v=Iewt4-w2R9g"
                      target="_blank"
                    >
                      <img
                        src={YoutubeIcon}
                        alt="Youtube Icon"
                      />
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="https://www.facebook.com/profile.php?id=61556689322251"
                      target="_blank"
                    >
                      <img
                        src={FacebookIcon}
                        alt="Facebook Icon"
                      />
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="https://www.instagram.com/aakashgangaopen/"
                      target="_blank"
                    >
                      <img
                        src={InstagramIcon}
                        alt="Facebook Icon"
                      />
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="https://twitter.com/Aakashgangopen"
                      target="_blank"
                    >
                      <img
                        style={{width: "40px"}}
                        src={TwitterIcon}
                        alt="Facebook Icon"
                      />
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="https://www.linkedin.com/company/aakashgangaopen/"
                      target="_blank"
                    >
                      <img
                        src={LinkedinIcon}
                        alt="Facebook Icon"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-12 customfootercontent"
            >
              <div className="ag_ftr_box">
                <h4 className="tagh4">learn</h4>
                <ul className="ag_ftr_links tagul">
                  <li className="tagli">
                    <Link
                      to="/author-submission-guidelines"
                      target="_blank"
                      className="taga"
                    >
                      Author Guidelines 
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="/peer-reviewer-guidelines"
                      target="_blank"
                      className="taga"
                    >
                      Reviewer Guidelines
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="/publishing-editor-roles-and-responsibilities"
                      target="_blank"
                      className="taga"
                    >
                      Editor Responsibilities
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      to="https://vikramshilaedu.in/resourcecentre"
                      target="_blank"
                      className="taga"
                    >
                      Resources: Videos, Blogs, PPTs
                    </Link>
                  </li>
                </ul>
                <h4 className="tagh4">participate</h4>
                <ul className="ag_ftr_links tagul">
                  <li className="tagli">
                    {!token && (
                      <Link to="javascript:;"
                        onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}
                        className="taga"
                      >
                        Submit an Article
                      </Link>
                    )}
                    {token && (
                      <Link
                        onClick={() => {
                          
                          isMobile ? setMobileAlert(true):setUploadArticle(true);
                        }}
                        
                        className="taga"
                      >
                        Submit an Article
                      </Link>
                    )}
                  </li>
                  <li className="tagli">
                    <Link
                      to="javascript:;"
                    onClick={() =>isMobile ? setMobileAlertReg(true): navigate("/registration")}
                      className="taga"
                    >
                      Register with Us
                    </Link>
                  </li>
                  <li className="tagli">
                    <Link
                      className="taga"
                      to="/faq" target="_blank"
                    >
                      FAQs
                    </Link>
                  </li>
                  {
                    token ? (
                      <li className="tagli">
                    <Link
                      to="/refer-and-earn"
                      className="taga blinkWrap"
                    >
                      <b>Refer and Earn</b>
                    </Link>
                  </li>
                    ) : (
                      <li className="tagli">
                      <Link
                      to="/refer-and-earn"
                      className="taga blinkWrap"
                    >
                      <b>Refer and Earn</b>
                    </Link>
                    </li>
                    )
                  }
                  
                </ul>
              </div>
              <div className="footer-align-bottom">
                <h4 className="tagh4">Write to us</h4>
                <ul className="ag_ftr_contacts tagul">
                  <li className="tagli">
                    <img src="/assets/images/icon-mail.svg" alt="Gmail Icon" />
                    <Link
                      to="mailto:contactus@vikramshilaedu.in"
                      className="taga"
                    >
                      contactus@vikramshilaedu.in
                    </Link>
                  </li>
                  <li className="tagli">
                    <img
                      src="/assets/images/icon-feedback.svg"
                      alt="Feedback Icon"
                    />
                    {!token && (
                      <Link
                        to="/login"
                        onClick={(e) => handleRedirect(e, "/login")}
                        className="taga"
                      >
                        Feedback
                      </Link>
                    )}
                    {token && (
                      <Link
                        onClick={() => setShowFeedbackModal(true)}
                        className="taga"
                      >
                        Feedback
                      </Link>
                    )}
                  </li>
                  <li className="tagli">
                    <img
                      src="/assets/images/icon-write.svg"
                      alt="Write For Us Icon"
                    />
                    <Link
                      className="taga"
                      to="/contact-us"
                      onClick={scrollLink}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 col-12">
              <div className="ag_copyright_wrap">
                <p className="tagp">
                  © 2023{" "}
                  <Link to="/" onClick={(e) => handleRedirect(e, "/")}>
                    Aakashganga
                  </Link>
                  . All rights reserved.
                </p>
                <p className="tagp">
                  Best viewed on Google Chrome and MS Edge for Windows and
                  Safari for MAC. Optimal Resolution: 1920X1080
                </p>
                <ul>
                  <li className="tagli">
                    <Link
                      to="/terms-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ReferLoginModal
          showLoginRefer={showLoginRefer}
          setShowLoginRefer={setShowLoginRefer}
        />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
        <MobileModalAlert mobileAlert={mobileAlert}
        setMobileAlert={setMobileAlert} />
        <MobileModalReg mobileAlertReg={mobileAlertReg}
        setMobileAlertReg={setMobileAlertReg} />
      </div>
    </>
  );
}

export default Footer;
