const ApiEndPoint = {
  AakashGanga: "/AakashGanga",
  Registration: "/Registration",
  VerifyUser:'/verifyUser',
  UserLogin: "/userLogin",
  Social: "/socialLogin",
  ForgotPassword: "/forgotPassword",
  ResetPassword: "/resetPassword",
  GetUserById: "/getUserById",
  GetPercentage: "/getPercentage",
  Profile_setup: "/profile_setup",
  GET_PR_BY_SEARCH: "/getAllAuthorForAdmin",
  GET_PR_ARTICLE: "/getPrArticle",
  GET_DICTIONARY: "/getDictionary",
  DELETE_DICTIONARY_WORD: "/deleteDictionaryWord",
  PR_REPORT: "/updatePrStatusForArticle",
  GET_PR_REPORT: "/getPrReport",
  RECOMMENDED_ARTICLES: '/getRecommendedArticles',
  APPOINT_PR: "/appointPrForArticle",
  SEND_PR_INVITATION: "/sentPrInvitation",
  paymentByPass: "/paymentByPass",
  REVIEW_ARTICLE: "/getArticleById",
  Profile_update: "/profile_update",
  Verifyotp:"/verify_otp",
  ChangePassword:"/changePassword",
  GetAllMasterData:'/getAllMaterDataVersion1',
  GET_SUBJECT_SPECIALIZATION: '/getSubjectSpecilisation',
  GetSpecialtiozation:'/getSpecialtiozation',
  SaveArticle:'/saveArticle',
  GetArticleByUserId:'/getArticleByUserId',
  GetArticleById:'/getArticleById',
  UpdateArticle:'/updateArticle',
  GET_PE_Article: '/getPeArticle',
  GET_SOCIETY_PE_Article: '/getSocietyPeArticle',
  GET_PR_Invitation: '/getPrInvitation',
  GET_SE_Article: '/getSESocietyArticle',
  UPDATE_SOCIETY_PAYMENT: '/societyPaymentUpdate',
  SAVE_REVOKE_COUPON: '/saveRevokeCoupon',
  ACCEPT_REJECT_REVIEW: '/updatePeStatusForArticleStatus',
  DOWNLOAD_PEER_REPORT: '/downloadPeerReport',
  NOT_ACCEPTED_REASON: '/getNotAcceptedPePrReason',
  GET_READING_LIST: '/getReadingListArticle',
  REMOVE_FROM_READING_LIST: '/removeArticleToReadingList',
  GET_REPORT_AFTER_FIRST_REVISION: '/getReportAfterFirstRevision',
  ResendOtpForVerification:'/resendOtpForVerification',
  VerifyUrl:'/verifyUrl',
  RemoveProfilePicture:'/removeProfilePicture',
  SubmitFeedback: '/submitFeedback',
  UPDATEMOBILE: '/changePhoneNumber',
  UPDATEMOBILEOTPVERIFY: '/verifyPhoneNumber',
  ADD_SPECIALIZATION: '/addOtherSpecialization',
  ADD_OTHER_SPECIALIZATION: '/addOtherSpecializationVersion1',
  GET_OTHER_SPECIALIZATION: '/addOtherSpecializationStatus',
  GetArticleWithoutToken:'/getArticleWithoutToken',
  UPDATEPROFILEPASSWORD: '/changePassword',
  UploadMedia: '/uploadMedia',
  ARTICLE_UPLOAD: '/getSimilarityCheckId',
  DOWNLOAD_SIMILARITY_REPORT: '/downloadSimiliratyReport',
  START_SIMILARITY: '/similiratyCkeckReportWithAllProcess',
  SUBSCRIBE: '/countMeIn',
  SUBSCRIBE_HOMEPAGE: '/countMeInAkashganga',
  SURCHGENRAL: '/searchJournal',
  GETMUDRAS:'/getMudras',
  GETMUDRAS_HISTORY:'/getHistoryOfMudras',
  GET_ALL_GRANTS: '/getAllGrants',
  GET_GRANTS_DETAIL: '/getGrantById',
  APPLY_GRANT:'/applyGrant',
  GET_GRANTS_DETAIL_BY_NAME:'/getGrantByName',
  GET_JOURNAL_DETAIL:'/getJournalDetails',
  FIND_REVIEWER:'/appointPeForArticle',
  GET_LATEST_ARTICLES:'/getLatestArticles',
  GET_MOST_VIEWED_ARTICLES:'/getMostViewedArticles',
  GET_LAST_BROWSE_ARTICLE:'/getLastBrowseArticle',
  ADD_ARTICLE_TO_READING_LIST:'/addArticleToReadingList',
  INITIATE_PAYMENT:'/initiate_payment',
  UpdatePaymentStatus:'/updatePaymentStatus',
  ADD_LAST_VIEW_ARTICLE:'/addLastViewArticle',
  ADD_AMENDMENT: '/addAmendment',
  UPDATE_ARTICLE_REQUEST: '/addUpdateArticleRequest',
  ADD_SHARE_AND_DOWNLOAD_COUNT:'/addShareAndDownloadCount',
  DOWNLOAD_OR_SEND_RECEIPT:'/downloadOrSendRecipt',
  GET_ALL_OFFER:'/getAllOfferForPayment',
  GET_OFFER_BY_ID:'/getOfferById',
  GET_USER_MUDRAS:'/getMudras',
  GET_APC_VALUE: '/getApcData',
  GET_PROFANITY_WORDS: '/getProfanityWords',
  SUBMIT_AUTHOR_FEEDBACK: '/submitAuthorFeedBack',
  SUBMIT_OPEN_REVIEW: '/submitOpenReview',
  GET_OPEN_REVIEW_FOR_ARTICLE: '/getOpenReviewForArticle',
  Get_Payment_History_For_User: '/getPaymentHistoryForUser',
  SUBMIT_FLAG_REVIEW: '/submitFlagReview',
  DOWNLOAD_ARTICLE_WORD: '/downloadWordFileArticle',
  GET_APPOINT_ROLE_FOR_PARTICULAR_PUBLISH_EDITOR: '/getAppointRoleForParticularPublishEditor',
  UPDATE_APPOINT_ROLE_FOR_PARTICULAR_PUBLISH_EDITOR: '/updateAppointRoleForParticularPublishEditor',
  USER_MUDRA_DETAILS: '/userMudrasDetail',
  GET_JOURNAL_SUBJECT_SPECIALIZATION: '/getJornalSubjectSpecilisation',
  CREATE_NEW_ARTICLE_VERSION: '/createNewArticleVersion',
  GET_ALL_ARTICLE_VERSION:'/getAllArticleVersion',
  GET_ARTICLE_PDF_DOWNLOAD:'/articlePdfDownload',
  UPDATE_AMENDMENT: '/updateAmendment',
  GET_USER_NOTIFICATION: '/getUserNotification',
  UPDATE_NOTIFICATION: '/updateNotification',
  GET_ALL_JOURNAL_ARTICLE: '/getAllJournalAndArticle',
  GRAMMAR_CHECK: '/grammerCheck',
  ADD_TO_DICTIONARY: '/addToDictionary',
  SubmitFeedbackAakashganga: '/submitFeedBackAkashganga',
  GET_PARTICULAR_JOURNAL_DETAILS:'/getParticularJournalDetails',
  GET_PE_ARTICLE_FOR_JOURNAL_HOMEPAGE: '/getPeArticleForJournalHomePage'
};

Object.freeze(ApiEndPoint);
export default ApiEndPoint;
