import Login from "../containers/autherFlow/login";
import submitArticle from "../components/common/submitArticle";
import ForgotPassword from "../containers/autherFlow/forgotpassword";
import MobileLogin from "../containers/autherFlow/mobileLogin";
import Registration from "../containers/autherFlow/registration";
import ResetPassword from "../containers/autherFlow/resetpassword";
import VerifyOtp from "../containers/autherFlow/verifyotp";
import { PATH } from "./path";
import PersonalDetails from "../containers/autherFlow/personalDetails/PersonalDetails";
import PaymentDetails from "../containers/autherFlow/paymentDetails/PaymentDetails";
import PeDashboard from "../containers/autherFlow/pedashboard";
import EditorDashboard from "../containers/autherFlow/EditorDashboard";
import EditorDashboardPrListing from "../containers/autherFlow/EditorDashboard/PRListing";
import PRInvitedListing from "../containers/autherFlow/EditorDashboard/PRInvitedListing.js";
import SEArticleList from "../containers/autherFlow/EditorDashboard/SEArticleList";
import SEbulkOrder from "../containers/autherFlow/EditorDashboard/SEbulkOrder";
import EditorDashboardPrAppointmentHis from "../containers/autherFlow/EditorDashboard/PublishingEditor";
import PrDashboard from "../containers/autherFlow/prdashboard";
import searchHeader from "../containers/autherFlow/searchHeader";
import mudraKosh from "../containers/autherFlow/mudraKosh";
import ReadingList from "../containers/autherFlow/readingList";
import myDictionary from "../containers/autherFlow/myDictionary";
import articleRegistrationPayment from "../containers/autherFlow/articleRegistrationPayment";
import history from "../containers/autherFlow/history";
import articleLanding from "../containers/autherFlow/articleLanding";
import articleLandingsociety from "../containers/autherFlow/articleLandingsociety";
import articleLandingmega from "../containers/autherFlow/articleLandingmega";
import allArticle from "../containers/autherFlow/allArticles";
import authorGuidelines from "../containers/autherFlow/authorGuidelines";
import perviewArticle from "../containers/autherFlow/previewarticle";
import viewPublishedArticle from "../components/viewArticle/viewPublishedArticle";
import viewPublishedArticleNew from "../components/viewArticle/viewPublishedArticleNew";

/* eslint-disable */
import SocietyMyProfile from "../containers/autherFlow/myProfile/SocietyMyProfile";
import MyProfile from "../containers/autherFlow/myProfile/MyProfile";
import MyProfileEdit from "../containers/autherFlow/myProfile/Edit";
import VerifyEmail from "../containers/autherFlow/verifyEmail";
import PreviewProfile from "../containers/autherFlow/previewProfile";
import AakashgangaAbout from "../components/about/About";
import Sitemap from "../components/sitemap/Sitemap";
import ContactUs from "../containers/ContactUs";
import NeedHelp from "../containers/ContactUs/needHelp";
import Error from "../containers/Error";
import FaqAkashganga from "../containers/FAQ/akashgangafaq";
import Cookie from "../containers/Cookie";
import WIP from "../containers/WIP";
import WIPAkashganga from "../containers/WIPAkashganga";
import TermsAkashganga from "../containers/Terms/akashgangatoc";
import PublishingEditor from "../containers/autherFlow/pedashboard/PublishingEditor";
import PeerReviewer from "../containers/autherFlow/prdashboard/PeerReview";
import Dashboard from "../containers/autherFlow/dashboard";
import AddArticle from "../containers/Dashboard/AddArticle";
import EditArticle from "../containers/Dashboard/EditArticle";
import EditArticleSociety from "../containers/Dashboard/EditArticleSociety";
import ViewReport from "../containers/Dashboard/Article/ViewReport";
import MyArticle from "../containers/Dashboard/MyArticle";
import ReviewArticle from "../containers/autherFlow/pedashboard/ReviewArticle";
import SubmitReview from "../containers/autherFlow/prdashboard/SubmitReview";
import NavigatingPE from "../containers/NavigatingPE";
import Home from "../akashgangaHtmlPages/HomeComponent";
import SellPage from "../akashgangaHtmlPages/SellPage";
import conferencePapers from "../akashgangaHtmlPages/conferencePapers";
import JournalComponent from "../akashgangaHtmlPages/Journals/JournalComponent";
import SamajShastraJournalComponent from "../akashgangaHtmlPages/Journals/SocietyHomepage";
import ArticleProcessingCharges from "../akashgangaHtmlPages/ArticleProcessingCharges";
import AuthorSubmissionGuidelines from "../akashgangaHtmlPages/AuthorSubmissionGuidelines";
import GuidelinesFroPeerReviewers from "../akashgangaHtmlPages/GuidelinesFroPeerReviewers";
import HowWePublish from "../akashgangaHtmlPages/HowWePublish";
import EthicsPolicy from "../akashgangaHtmlPages/EthicsPolicy";
import PeerReviewModels from "../akashgangaHtmlPages/PeerReviewModels";
import PublishWithUs from "../akashgangaHtmlPages/PublishWithUs";
import PublishingEditorRolesAndResponsibilities from "../akashgangaHtmlPages/PublishingEditorRolesAndResponsibilities";
import SellPageForeign from "../akashgangaHtmlPages/SellPageForeign";
import UnlockingKnowledgeForAll from "../akashgangaHtmlPages/UnlockingKnowledgeForAll";
import WelcomeToMegaJournals from "../akashgangaHtmlPages/WelcomeToMegaJournals";
import FaqPage from "../akashgangaHtmlPages/FaqPage";
import ContactUsAaakashganga from "../akashgangaHtmlPages/ContactUsAakash";
import GetInTouch from "../akashgangaHtmlPages/GetInTouch";
import PublishEditor from "../akashgangaHtmlPages/PublishEditor";
import EditorialBoard from "../akashgangaHtmlPages/EditorialBoard";
import SocietyEditorialBoard from "../akashgangaHtmlPages/SocietyEditorialBoard.js";
import MegaEditorialBoard from "../akashgangaHtmlPages/MegaEditorialBoard";
import ReferAndEarn from "../akashgangaHtmlPages/ReferAndEarn";

export const publicRouteNavigators = [
  {
    name: "login",
    path: PATH.LOGIN,
    PropComponent: Login,
  },
  {
    name: "forgotPassword",
    path: PATH.FORGOTPASSWORD,
    PropComponent: ForgotPassword,
  },
  {
    name: "mobileLogin",
    path: PATH.MOBILELOGIN,
    PropComponent: MobileLogin,
  },
  {
    name: "registration",
    path: PATH.REGISTRATION,
    PropComponent: Registration,
  },
  {
    name: "reset-password",
    path: PATH.RESETPASSWORD,
    PropComponent: ResetPassword,
  },
  {
    name: "verifyOtp",
    path: PATH.VERIFYOTP,
    PropComponent: VerifyOtp,
  },
  {
    name: "verifyEmail",
    path: PATH.VERIFYEMAIL,
    PropComponent: VerifyEmail,
  },
];

export const protectedRouteNavigators = [
  {
    name: "personalDetails",
    path: PATH.PERSONAL_DETAILS,
    PropComponent: PersonalDetails,
  },
  {
    name: "NeedHelp",
    path: PATH.NEED_HELP,
    PropComponent: NeedHelp,
  },
  {
    name: "paymentDetails",
    path: PATH.PAYMENT_DETAILS,
    PropComponent: PaymentDetails,
  },
  {
    name: "dashboard",
    path: PATH.DASHBOARD.DASHBOARD,
    PropComponent: Dashboard,
  },
  {
    name: "submitArticle",
    path: PATH.DASHBOARD.SUBMIT_ARTICLE,
    PropComponent: AddArticle,
  },
  {
    name: "editArticle",
    path: PATH.DASHBOARD.EDIT_ARTICLE,
    PropComponent: EditArticle,
  },
  {
    name: "myArticle",
    path: PATH.DASHBOARD.MY_ARTICLE_DRAFT,
    PropComponent: MyArticle,
  },
  {
    name: "myArticle",
    path: PATH.DASHBOARD.MY_ARTICLE_UNDER_REVIEW,
    PropComponent: MyArticle,
  },
  {
    name: "myArticle",
    path: PATH.DASHBOARD.MY_ARTICLE_APPROVED,
    PropComponent: MyArticle,
  },
  {
    name: "myArticle",
    path: PATH.DASHBOARD.MY_ARTICLE_REJECTED,
    PropComponent: MyArticle,
  },
  {
    name: "myArticle",
    path: PATH.DASHBOARD.MY_ARTICLE_PUBLISHED,
    PropComponent: MyArticle,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_DRAFT,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_ACCEPTED,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_SENT_FOR_REVIEW,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_PEER_REVIEW,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_APPROVED,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_REVISION,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_REJECTED,
    PropComponent: PublishingEditor,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR_NOT_ACCEPTED,
    PropComponent: PublishingEditor,
  },
  {
    name: "viewReport",
    path: PATH.DASHBOARD.ARTICLE.VIEW_REPORT,
    PropComponent: ViewReport,
  },
  {
    name: "submitReview",
    path: PATH.DASHBOARD.ARTICLE.SUBMIT_REVIEW,
    PropComponent: SubmitReview,
  },
  {
    name: "viewArticle",
    path: PATH.DASHBOARD.ARTICLE.REVIEW_ARTICLE,
    PropComponent: ReviewArticle,
  },
  {
    name: "peerReviewer",
    path: PATH.DASHBOARD.PEER_REVIEWER,
    PropComponent: PrDashboard,
  },
  {
    name: "peerReviewer",
    path: PATH.DASHBOARD.PEER_REVIEWER_REQUESTS,
    PropComponent: PeerReviewer,
  },
  {
    name: "peerReviewer",
    path: PATH.DASHBOARD.PEER_REVIEWER_ACCEPTED,
    PropComponent: PeerReviewer,
  },
  {
    name: "peerReviewer",
    path: PATH.DASHBOARD.PEER_REVIEWER_REVIEWED,
    PropComponent: PeerReviewer,
  },
  {
    name: "peerReviewer",
    path: PATH.DASHBOARD.PEER_REVIEWER_NOT_ACCEPTED,
    PropComponent: PeerReviewer,
  },
  {
    name: "publishingEditor",
    path: PATH.DASHBOARD.PUBLISHING_EDITOR,
    PropComponent: PeDashboard,
  },
  {
    name: "dashboardEditor",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR,
    PropComponent: EditorDashboard,
  },
  {
    name: "dashboardEditor",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_PE_LISTING,
    PropComponent: EditorDashboardPrListing,
  },
  {
    name: "SocietyMyProfile",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_SE_USERPROFILE,
    PropComponent: SocietyMyProfile,
  },
  {
    name: "dashboardEditorInvitedList",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_PR_INVITED_LIST,
    PropComponent: PRInvitedListing,
  },
  {
    name: "dashboardEditorEditArticle",
    path: PATH.DASHBOARD.DASHBOARD_SE_EDIT_ARTICLE,
    PropComponent: EditArticleSociety,
  },
  {
    name: "dashboardEditorPaymentBypass",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_PAYMENT_BYPASS,
    PropComponent: SEArticleList,
  },
  {
    name: "dashboardEditorBulkOrder",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_BULK_ORDER,
    PropComponent: SEbulkOrder,
  },
  {
    name: "dashboardEditorPrAppointHisty",
    path: PATH.DASHBOARD.DASHBOARD_EDITOR_PR_APPOINTMENT_HISTORY,
    PropComponent: EditorDashboardPrAppointmentHis,
  },
  {
    name: "registrationPayment",
    path: PATH.ARTICLEREGISTRATIONPAYMENT,
    PropComponent: articleRegistrationPayment,
  },
  {
    name: "mudraKosh",
    path: PATH.MUDRAKOSH,
    PropComponent: mudraKosh,
  },
  {
    name: "history",
    path: PATH.HISTORY,
    PropComponent: history,
  },
  // {
  //   name: "faq-akashganga",
  //   path: PATH.FAQ_AKASHGANGA,
  //   PropComponent: FaqAkashganga,
  // },
  {
    name: "authorGuidelines",
    path: PATH.AUTHORGUIDELINE,
    PropComponent: authorGuidelines,
  },
  {
    name: "perviewArticle",
    path: PATH.PREVIEWARTICLE,
    PropComponent: perviewArticle,
  },
  {
    name: "redingList",
    path: PATH.READINGLIST,
    PropComponent: ReadingList,
  },
  {
    name: "myDictionary",
    path: PATH.MYDICTIONARY,
    PropComponent: myDictionary,
  },
  {
    name: "myProfile",
    path: PATH.MYPROFILE,
    PropComponent: MyProfile,
  },
  {
    name: "editProfile",
    path: PATH.EDITPROFILE,
    PropComponent: MyProfileEdit,
  },
  {
    name: "previewProfile",
    path: PATH.PREVIEWPROFILE,
    PropComponent: PreviewProfile,
  },
  {
    name: "sun-editor",
    path: PATH.SUNEDITOR,
    PropComponent: submitArticle,
  },
];

export const allUserRouteNavigators = [
  {
    name: "articleLanding",
    path: PATH.ARTICLELANDING,
    PropComponent: articleLanding,
  },
  {
    name: "articleLandingsociety",
    path: PATH.ARTICLELANDINGSOCIETY,
    PropComponent: articleLandingsociety,
  },
  {
    name: "allarticles",
    path: PATH.ALL_ARTICLES,
    PropComponent: allArticle,
  },
  {
    name: "searchHeader",
    path: PATH.SEARCH_HEADER,
    PropComponent: searchHeader,
  },
  {
    name: "terms-conditions",
    path: PATH.TERMSCONDITION,
    PropComponent: TermsAkashganga,
  },
  {
    name: "wip-kaashi",
    path: PATH.WIP,
    PropComponent: WIP,
  },
  {
    name: "wip-akashganga",
    path: PATH.WIP_AKASHGANGA,
    PropComponent: WIPAkashganga,
  },
  {
    name: "contactus",
    path: PATH.CONTACT_US,
    PropComponent: ContactUs,
  },
  {
    name: "404",
    path: PATH.ERROR,
    PropComponent: Error,
  },
  {
    name: "cookie",
    path: PATH.COOKIE,
    PropComponent: Cookie,
  },
  {
    name: "navigatingPE",
    path: PATH.DASHBOARD.NAVIGATOR_PUBLISHING_EDITOR,
    PropComponent: NavigatingPE,
  },
  {
    name: "viewPublishedArticle",
    path: PATH.VIEW_PUBLISHED_ARTICLE,
    PropComponent: viewPublishedArticle,
  },
  {
    name: "viewPublishedArticle",
    path: PATH.VIEW_PUBLISHED_ARTICLE_NEW,
    PropComponent: viewPublishedArticleNew,
  },


  {
    name: "aakashgangaHomepage",
    path: PATH.AAKASHGANGA_HOMEPAGE,
    PropComponent: Home,
  },
  {
    name: "aakashgangaAbout",
    path: PATH.AAKASHGANGA_ABOUT,
    PropComponent: AakashgangaAbout,
  },
  {
    name: "aakashgangaReferAndEarn",
    path: PATH.REFER_AND_EARN,
    PropComponent: ReferAndEarn,
  },
  {
    name: "sitemap",
    path: PATH.SITEMAP,
    PropComponent: Sitemap,
  },
  {
    name: "sellPage",
    path: PATH.SELL_PAGE,
    PropComponent: SellPage,
  },
  {
    name: "conferencePapers",
    path: PATH.CONFERENCE_PAPERS,
    PropComponent: conferencePapers,
  },
  {
    name: "publishEditor",
    path: PATH.PUBLISHING_EDITORS,
    PropComponent: PublishEditor,
  },
  {
    name: "editorialBoard",
    path: PATH.EDITORIAL_BOARD,
    PropComponent: EditorialBoard,
  },
  {
    name: "societyeditorialBoard",
    path: PATH.SOCIETY_EDITORIAL_BOARD,
    PropComponent: SocietyEditorialBoard,
  },
  {
    name: "megaeditorialBoard",
    path: PATH.MEGA_EDITORIAL_BOARD,
    PropComponent: MegaEditorialBoard,
  },
  {
    name: "exploreJournals",
    path: PATH.EXPLORE_JOURNALS,
    PropComponent: JournalComponent,
  },
  {
    name: "exploresociety",
    path: PATH.EXPLORE_SOCIETY,
    PropComponent: SamajShastraJournalComponent,
  },
  {
    name: "exploresociety",
    path: PATH.EXPLORE_SOCIETY_ALLARTICLE,
    PropComponent: articleLandingsociety,
  },
  {
    name: "exploresociety",
    path: PATH.EXPLORE_MEGA_ALLARTICLE,
    PropComponent: articleLandingmega,
  },
  {
    name: "articleProcessingCharges",
    path: PATH.ARTICLE_PROCESSION_ChARGES,
    PropComponent: ArticleProcessingCharges,
  },
  {
    name: "authorSubmissionGuidelines",
    path: PATH.AUTHOR_SUBMISSION_GUIDELINES,
    PropComponent: AuthorSubmissionGuidelines,
  },
  {
    name: "peerReviewerGuidelines",
    path: PATH.PEER_REVIEWER_GUIDELINES,
    PropComponent: GuidelinesFroPeerReviewers,
  },
  {
    name: "howWePublish",
    path: PATH.HOW_WE_PUBLISH,
    PropComponent: HowWePublish,
  },
  {
    name: "EthicsPolicy",
    path: PATH.ETHICS_POLICY,
    PropComponent: EthicsPolicy,
  },
  {
    name: "peerReviewerModal",
    path: PATH.PEER_REVIEWER_MODAL,
    PropComponent: PeerReviewModels,
  },
  {
    name: "publishWithUs",
    path: PATH.PUBLISH_WITH_US,
    PropComponent: PublishWithUs,
  },
  {
    name: "publishingEditorAndResponsibility",
    path: PATH.PUBLISHING_EDITOR_AND_RESPONSIBILITY,
    PropComponent: PublishingEditorRolesAndResponsibilities,
  },
  {
    name: "selPageForeign",
    path: PATH.SELL_PAGE_FOREIGN,
    PropComponent: SellPageForeign,
  },
  {
    name: "unlockingKnowldge",
    path: PATH.UNLOCKING_KNOWDGE,
    PropComponent: UnlockingKnowledgeForAll,
  },
  {
    name: "welcomeToMegajournals",
    path: PATH.WELCOME_TO_MEGAJOURNALS,
    PropComponent: WelcomeToMegaJournals,
  },
  {
    name: "FAQ",
    path: PATH.AAKASHGANGA_FAQ,
    PropComponent: FaqPage,
  },
  {
    name: "ContactUsAaakashganga",
    path: PATH.AAKASHGANGA_CONTACT_US,
    PropComponent: ContactUsAaakashganga,
  },
  {
    name: "GetInTouch",
    path: PATH.JOURNAL_GET_IN_TOUCH,
    PropComponent: GetInTouch,
  },
];
