import React from 'react';
import fullscreen from "../../assets/img/icons/fullscreen.svg";

const PublishedDate = ({ pagename, details, onChange }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onChange();
    };
 
    // console.log("details", details);
    return (
        <div className="publishedBox stylechange">
            <div className="innerMergin justify-content-between">
                <div className="d-flex fs-18">
                    <span className="theme-orange-color">Published: &nbsp;</span> 
                    <div>
                        {pagename === 'previewArticle' || details?.status !== "published" ? "--" : details?.publishedDate}
                    </div>
                    {details?.volume && <>
                    <span className="ms-4 theme-orange-color">Volume: {} &nbsp;</span> 
                    <div>
                        {details?.volume|| ""}
                    </div>
                    <span className="ms-3 theme-orange-color">Issue: &nbsp;</span> 
                    <div>
                        {details?.issue || ""}
                    </div>
                    </>}

                    {details?.doiUrl && (
                        <a className="ms-5" href={details.doiUrl}>{details.doiUrl}</a>
                    )}
                </div>
                
                {pagename === "viewPublishedArticle" && (
                    <div className="viewcommentline">
                        <a className="me-5" href="#fullscreen" onClick={handleClick}>Read Full Screen <img className="ps-2" src={fullscreen} alt="fullscreen icon" /></a>
                        <a href="#viewScrollReviews">View comments on this article</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PublishedDate;
