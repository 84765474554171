import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { identityOptions, issueOptions, titleOptionsNew } from "../constants";
import Select from "../components/common/Select";
import { validatePhoneNumber } from "../utils";
import { BASE_URL } from "../config";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';
const ContactUsAakashComponent = ({ page, contactSuccess }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const initialValues = {
    title: "",
    fullName: "",
    emailId: "",
    code: "",
    mobile: "",
    identity: "",
    othersData: "",
    description: "",
    issueType: "",
    othersIssue: "",
  };
  const setParams = (values) => {
    const params = {
      title: values.title,
      fullName: values.fullName,
      email: values.emailId,
      code: values.code,
      phoneNumber: values.mobile,
      identify:
        formRef.current?.values?.identity === "Others"
          ? values.othersData
          : values.identity,
      helpData: values.description,
      issueType:
        formRef?.current?.values?.issueType === "Others"
          ? values?.othersIssue
          : values?.issueType,
    };
    return params;
  };
  const validate = Yup.object({
    fullName: Yup.string().required("*Full Name is required"),
    emailId: Yup.string()
      .email("*Email is invalid")
      .required("*Email is required"),
    identity: Yup.string().required("*Identity is required"),
    othersData: Yup.string().when("identity", {
      is: (value) => value === "Others",
      then: (schema) => schema.required("*Please specify your identity"),
    }),
    description: Yup.string().required("*Description is required"),
    mobile: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Mobile Number is Invalid"
      )
      .min(10, "Mobile Number is Invalid"),
  });
  const contactUsData = async (values) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const response = await axios.post(
      `${BASE_URL}AakashGanga/contactUs`,
      setParams(values)
    );
    dispatch({ type: "SET_LOADER", payload: false });
    if (response?.status === 200) {
      setShow(true);
    } else {
      toast.error("Please try again!!");
    }
  };

  return (
    <div className="tagbody">
       <Helmet>
          <title>Contact | Get in touch |  Aakashganga</title>
          <meta name="description" content="Connect with Aakashganga Open for inquiries, support, or collaboration opportunities." />

          <meta property="og:title" content="Contact | Get in touch |  Aakashganga" data-react-helmet="true" />
          <meta property="og:description" content="Connect with Aakashganga Open for inquiries, support, or collaboration opportunities." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/contact-us" data-react-helmet="true" />

          <link rel="canonical" href="https://www.aakashgangaopen.in/contact-us" data-react-helmet="true" />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <AakashgangaHeader />
      <h1 className="seo-related-tag">
        Contact Us
      </h1>
      <div className="markitingContact">
        <div className="contactSection innerPages">
          <div className="containWrap">
            <div className="contactLeft">
              <h1>
                <span className="">Contact Us</span>
              </h1>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                innerRef={formRef}
                validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                  contactUsData(values);
                  resetForm();
                }}
              >
                {(props) => (
                  <Form>
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="row mb-5">
                            <div className="col-4">
                              <div className="fieldWrap-mobile">
                                <Select
                                  handleChange={(value) =>
                                    props.setFieldValue("title", value)
                                  }
                                  selectedValue={props?.values.title}
                                  options={titleOptionsNew?.map(
                                    (val) => val?.key
                                  )}
                                  optionValues={titleOptionsNew?.map(
                                    (val) => val?.value
                                  )}
                                  labelClass={
                                    props?.values?.title ? "" : "hidden"
                                  }
                                  label="Title"
                                  placeholder="Title"
                                  name="title"
                                />
                              </div>
                            </div>
                            <div className="col-8 ps-0">
                              <div className="fieldWrap">
                                <label
                                  className={
                                    props?.values?.fullName ? "" : "hidden"
                                  }
                                >
                                  {"Full Name "}
                                </label>
                                <input
                                  type="text"
                                  className="fieldForm"
                                  placeholder="Full Name*"
                                  onChange={(event) =>
                                    props.setFieldValue(
                                      "fullName",
                                      event.target.value
                                    )
                                  }
                                  name="fullName"
                                  value={props.values.fullName}
                                />
                                {props?.errors?.fullName &&
                                props?.touched.fullName ? (
                                  <div className="error-field">
                                    {props?.errors?.fullName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="fieldIcon mb-5">
                            <div className="fieldWrap">
                              <label
                                className={
                                  props?.values?.emailId ? "" : "hidden"
                                }
                              >
                                {"Email Id "}
                              </label>
                              <b className="icon-email" />
                              <input
                                type="email"
                                className="fieldForm"
                                placeholder="Email ID*"
                                onChange={(event) =>
                                  props.setFieldValue(
                                    "emailId",
                                    event.target.value
                                  )
                                }
                                name="emailId"
                                value={props.values.emailId}
                              />
                              {props?.errors?.emailId &&
                              props?.touched.emailId ? (
                                <div className="error-field">
                                  {props?.errors?.emailId}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="row mb-5">
                            <div className="col-4">
                              <div className="mobileCode">
                                <label>+91</label>
                              </div>
                            </div>
                            <div className="col-8 ps-0">
                              <div className="fieldIcon">
                                <div className="fieldWrap-mobile">
                                  <label
                                    className={
                                      props?.values?.mobile ? "" : "hidden"
                                    }
                                  >
                                    Mobile Number
                                  </label>
                                  <b className="icon-call" />
                                  <input
                                    type="tel"
                                    className="fieldForm"
                                    placeholder="Mobile Number"
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "mobile",
                                        validatePhoneNumber(event.target.value)
                                      )
                                    }
                                    name="mobile"
                                    value={props.values.mobile}
                                    maxLength={10}
                                  />
                                  {props?.errors?.mobile &&
                                  props?.touched.mobile ? (
                                    <div className="error-field">
                                      {props?.errors?.mobile}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-5">
                            <div className="fieldWrap">
                              <Select
                                handleChange={(value) =>
                                  props.setFieldValue("identity", value)
                                }
                                label="Identity"
                                selectedValue={props?.values.identity}
                                options={identityOptions?.map(
                                  (val) => val?.key
                                )}
                                optionValues={identityOptions?.map(
                                  (val) => val?.value
                                )}
                                labelClass={
                                  props?.values?.identity ? "" : "hidden"
                                }
                                placeholder="Identity"
                                name="identity"
                              />
                              {props?.errors?.identity &&
                              props?.touched.identity ? (
                                <div className="error-field">
                                  {props?.errors?.identity}
                                </div>
                              ) : null}
                            </div>
                            {props?.values?.identity === "Others" ? (
                              <div  className="otherStyleMargin">
                                <input
                                  type="text"
                                  onChange={(event) =>
                                    props.setFieldValue(
                                      "othersData",
                                      event.target.value
                                    )
                                  }
                                  value={props?.values?.othersData}
                                  name="othersData"
                                  className="fieldForm"
                                  placeholder="Please enter"
                                />
                              </div>
                            ) : null}
                            {props?.errors?.othersData &&
                            props?.touched.othersData ? (
                              <div className="error-field">
                                {props?.errors?.othersData}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {page === "needHelp" ? (
                          <div className="col-12 col-md-6">
                            <div className="mb-5">
                              <div className="fieldWrap-mobile">
                                <Select
                                  handleChange={(value) =>
                                    props.setFieldValue("issueType", value)
                                  }
                                  selectedValue={props?.values.issueType}
                                  options={issueOptions?.map((val) => val?.key)}
                                  optionValues={issueOptions?.map(
                                    (val) => val?.value
                                  )}
                                  labelClass={
                                    props?.values?.issueType ? "" : "hidden"
                                  }
                                  label="Issue Type"
                                  placeholder="Select Issue Type"
                                  name="issueType"
                                />
                              </div>
                              {props?.values?.issueType === "Others" ? (
                                <div  className="otherIssuesMargin">
                                  <input
                                    type="text"
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "othersIssue",
                                        event.target.value
                                      )
                                    }
                                    value={props?.values?.othersIssue}
                                    name="othersIssue"
                                    className="fieldForm"
                                    placeholder="Please enter"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        <div className="col-12">
                          <label
                            
                            className="labelForm helpColorStyle"
                          >
                            How can we help you?<sup>*</sup>
                          </label>
                          <textarea
                            className="fieldForm"
                            placeholder="Let us know your concern and we will get back to you ASAP"
                            onChange={(event) =>
                              props.setFieldValue(
                                "description",
                                event.target.value
                              )
                            }
                            name="description"
                            value={props.values.description}
                          />
                          {props?.errors?.description &&
                          props?.touched.description ? (
                            <div className="error-field">
                              {props?.errors?.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="text-center pt-4 mb-0">
                        <button
                          type="submit"
                          className="button button-fill button-sm"
                          disabled={!(props.isValid && props.dirty)}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              
            </div>
          </div>
          <div className="d-flex contact-us-details justify-content-around">
                
                <div className="item">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#336d91" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                    </svg>
                  </div>
                <p className="fw-bold theme-blue-color">Email</p>
                <p className="mt-0 f-18 p-4 lh-lg pt-5">contactus@vikramshilaedu.in <br/>customersupport@vikramshilaedu.in</p>
                </div>
                <div className="item">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#336d91" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </div>
                  <p className="fw-bold theme-blue-color">Postal Address</p>
                  <p className="mt-0 f-18 p-4"><b>Vikramshila Research Private Limited</b>
                    <br />
                    I - 1667, 3rd Floor, CR Park, <br /> New Delhi - 110 019
                    <br /> Time - Monday - Saturday (9.30 - 17.30)</p>
                </div>
                <div className="item">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#336d91" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                    </svg>
                  </div>
                <p className="fw-bold theme-blue-color">Phone Number</p>
                <p className="mt-0 f-18 p-4 pt-5 lh-lg">+91-9810318933</p>
                  
                </div>
              </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="md-x"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            
            closeButton
          >
            <Modal.Title className="modal-title">
              <p className="good-news mb-3">
                Your message is on its way to us.
              </p>
              <p className="good-news msgPopupStyle1" >
                We will get back to you within two working days.
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter">
            <button
              className="button button-fill button-sm"
              onClick={handleClose}
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <AakashgangaFooter />
    </div>
  );
};
export default ContactUsAakashComponent;
